/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import Seo from '@widgets/Seo'
import React from 'react'

import { FooterCTA } from '../components/FooterCTA'
import { LoneRose } from '../components/LoneRose'

const Page = ({ path }) => (
  <>
    <Seo pageUrl={path} title="Press" />
    <Main style={{ padding: '0 36px' }} variant="compact">
      <p>Press</p>
    </Main>
    <LoneRose />
    <FooterCTA />
  </>
)

export default Page
